class FetchError extends Error {
	res: Response;
	constructor(res: Response, text: string) {
		super(res.status + " | " + text);
		this.res = res;
	}
}

class UnauthorizedError extends FetchError {
	constructor(res: Response, text: string) {
		super(res, text);
	}
}

async function fetchThrowOnBadResponse(
	input: RequestInfo | URL,
	init?: RequestInit
) {
	const res = await fetch(input as string, init);
	if (!res.ok) {
		if (res.status === 401) {
			throw new UnauthorizedError(res, "Unauthorized");
		}
		const text = await res.text();
		throw new FetchError(res, text);
	}
	return res;
}

class BaseClient {
	baseURL: string;
	baseHeaders: Record<string, string>;
	jsonHeaders: Record<string, string>;

	async callFetch(input: RequestInfo | URL, init?: RequestInit) {
		try {
			const res = await fetchThrowOnBadResponse(input, {
				...init,
				credentials: "include",
			});
			if (
				res.status !== 204 &&
				res.headers.get("content-type")?.includes("application/json")
			) {
				return await res.json();
			}
			return await res.text();
		} catch (err) {
			if (err instanceof UnauthorizedError) {
				//@ts-expect-error 2339
				if (this.handleUnauthorized) {
					//@ts-expect-error 2339
					this.handleUnauthorized(err.res);
				}
			}
			throw err;
		}
	}

	async get<T = unknown>(
		path: string,
		queryParams?: Record<string, string | number | boolean | string[] | number[]>,
	): Promise<T> {
		const url = new URL(`.${path}`, this.baseURL);
		if (queryParams) {
			const searchParams = new URLSearchParams(
				queryParams as Record<string, string>
			);
			url.search = searchParams.toString();
		}
		return await this.callFetch(url, {
			headers: this.baseHeaders,
			credentials: "include",
		});
	}

	async post<T = unknown>(path, payload, credentials?: 'include' | 'same-origin' | 'omit'): Promise<T> {
		const url = new URL(`.${path}`, this.baseURL);
		const headers =
			payload instanceof FormData ? this.baseHeaders : this.jsonHeaders;
		const body = payload instanceof FormData ? payload : JSON.stringify(payload);
		return await this.callFetch(url, {
			method: "POST",
			headers,
			body,
			credentials,
		});
	}

	async patch<T = unknown>(path, payload): Promise<T> {
		const url = new URL(`.${path}`, this.baseURL);
		const headers =
			payload instanceof FormData ? this.baseHeaders : this.jsonHeaders;
		const body = payload instanceof FormData ? payload : JSON.stringify(payload);
		return await this.callFetch(url, {
			method: "PATCH",
			headers,
			body,
		});
	}

	async put<T = unknown>(path, payload): Promise<T> {
		const url = new URL(`.${path}`, this.baseURL);
		return await this.callFetch(url, {
			method: "PUT",
			headers: this.jsonHeaders,
			body: JSON.stringify(payload),
		});
	}

	async delete<T = unknown>(path): Promise<T> {
		const url = new URL(`.${path}`, this.baseURL);
		return await this.callFetch(url, {
			method: "DELETE",
			headers: this.jsonHeaders,
		});
	}

	async rawFetch(
		path: string,
		init: RequestInit = {
			method: "GET",
		}
	) {
		const url = new URL(`.${path}`, this.baseURL);
		init.headers = this.baseHeaders;
		return await fetch(url.toString(), init);
	}
}

type UnauthorizedListener = (res: Response) => void;

export class ApiClient extends BaseClient {
	static unauthorizedListeners: Array<UnauthorizedListener> = [];

	constructor(token?: string) {
		super();
		this.baseURL = `${import.meta.env.VITE_API_BASE_URL}/v1/`;
		this.baseHeaders = {};
		if (token) {
			this.baseHeaders["x-access-token"] = token;
		}
		this.jsonHeaders = {
			...this.baseHeaders,
			"Content-Type": "application/json",
		};
	}

	static addUnauthorizedListener(listener: UnauthorizedListener) {
		ApiClient.unauthorizedListeners.push(listener);
	}

	static removeUnauthorizedListener(listener: UnauthorizedListener) {
		ApiClient.unauthorizedListeners = ApiClient.unauthorizedListeners.filter(
			(l) => l !== listener
		);
	}

	handleUnauthorized(res: Response) {
		for (const listener of ApiClient.unauthorizedListeners) {
			listener(res);
		}
	}
}

export class ORApiClient extends BaseClient {
	constructor(token?: string) {
		super();
		this.baseURL = import.meta.env.VITE_OR_API_BASE_URL as string;
		this.baseHeaders = {};
		if (token) {
			this.baseHeaders["access-token"] = token;
		}
		this.jsonHeaders = {
			...this.baseHeaders,
			"Content-Type": "application/json",
		};
	}
}
