import React from "react";
import { Outlet, Route, Routes, Navigate } from "react-router-dom";
import AppProviders from "./AppProviders";
import Toast from "@app/components/Toast";
import WebinarsEnabledProtected from "./components/WebinarsEnabledProtected";
import './tailwind.css';

const AuthProtected = React.lazy(() => import("./components/AuthProtected"));

const MagicLinkPage = React.lazy(() => import("./pages/auth/magic"));
const ORAuthPage = React.lazy(() => import("./pages/auth/or"));

const AcceptInvitationPage = React.lazy(() =>
	import("./pages/webinars/[url-key]/invitations/[invitationId]")
);

const Webinar = React.lazy(() => import("./pages/events/[webinarId]"));
const WebinarManagerLayout = React.lazy(() =>
	import("./components/webinar/WebinarManagerLayout")
);

const EmbeddedForm = React.lazy(() => import("./pages/embed/form"));

const RegistrationPage = React.lazy(() =>
	import("./pages/registration/registration")
);

const Events = React.lazy(() => import("./pages/events"));
const NewEvent = React.lazy(() => import("./pages/events/new"));
const DetailsPage = React.lazy(() =>
	import("./pages/events/[webinarId]/details")
);
const AdvancedPage = React.lazy(() =>
	import("./pages/events/[webinarId]/advanced")
);
const Polls = React.lazy(() =>
	import("./pages/events/[webinarId]/engagement/polls")
);
const QA = React.lazy(() => import("./pages/events/[webinarId]/engagement/qa"));
const Engagement = React.lazy(() =>
	import("./pages/events/[webinarId]/engagement")
);
const Speakers = React.lazy(() =>
	import("./pages/events/[webinarId]/speakers")
);
const Analytics = React.lazy(() =>
	import("./pages/events/[webinarId]/analytics")
);
const Registrations = React.lazy(() =>
	import("./pages/events/[webinarId]/registrations")
);

const Presentation = React.lazy(() =>
	import("./pages/events/[webinarId]/presentation")
);

const Emails = React.lazy(() => import("./pages/events/[webinarId]/emails"));
const Recordings = React.lazy(() =>
	import("./pages/events/[webinarId]/recordings")
);

const Automation = React.lazy(() =>
	import("./pages/events/[webinarId]/automation")
);

const Playlist = React.lazy(() =>
	import("./pages/events/[webinarId]/playlist")
);

const AppRoutes = () => {
	return (
		<Routes>
			<Route
				path="/auth/magic"
				element={
					<React.Suspense fallback={""}>
						<MagicLinkPage />
					</React.Suspense>
				}
			/>
			<Route
				path="/auth/or"
				element={
					<React.Suspense fallback={""}>
						<ORAuthPage />
					</React.Suspense>
				}
			/>
			<Route
				path="/webinars/:urlKey/invitations/:invitationId"
				element={
					<React.Suspense fallback={""}>
						<AcceptInvitationPage />
					</React.Suspense>
				}
			/>
			<Route
				path="/webinars/:urlKey/embed/form/"
				element={
					<React.Suspense fallback={""}>
						<EmbeddedForm />
					</React.Suspense>
				}
			/>
			<Route
				path="/webinars/:urlKey"
				element={
					<React.Suspense fallback={""}>
						<RegistrationPage />
					</React.Suspense>
				}
			/>
			<Route
				path="/webinars-manager/events"
				element={
					<React.Suspense fallback={<>...</>}>
						<AuthProtected>
							<WebinarsEnabledProtected>
								<WebinarManagerLayout>
									<Outlet />
								</WebinarManagerLayout>
							</WebinarsEnabledProtected>
						</AuthProtected>
					</React.Suspense>
				}
			>
				<Route
					index
					element={
						<React.Suspense fallback={<>...</>}>
							<Events />
						</React.Suspense>
					}
				/>
				<Route
					path="new"
					element={
						<React.Suspense fallback={<>...</>}>
							<NewEvent />
						</React.Suspense>
					}
				/>
				<Route
					path=":webinarId"
					element={
						<React.Suspense fallback={<>...</>}>
							<Webinar />
						</React.Suspense>
					}
				>
					<Route
						index
						element={
							<React.Suspense fallback={<>...</>}>
								<DetailsPage />
							</React.Suspense>
						}
					/>
					<Route
						path="engagement"
						element={
							<React.Suspense fallback={<>...</>}>
								<Engagement />
							</React.Suspense>
						}
					>
						<Route
							path="polls"
							element={
								<React.Suspense fallback={<>...</>}>
									<Polls />
								</React.Suspense>
							}
						/>
						<Route
							path="qa"
							element={
								<React.Suspense fallback={<>...</>}>
									<QA />
								</React.Suspense>
							}
						/>
						<Route index element={<Navigate to="./polls" />} />
					</Route>
					<Route path="polls" element={<Navigate to="../engagement/polls" />} />
					<Route path="qa" element={<Navigate to="../engagement/qa" />} />
					<Route
						path="speakers"
						element={
							<React.Suspense fallback={<>...</>}>
								<Speakers />
							</React.Suspense>
						}
					/>
					<Route
						path="emails"
						element={
							<React.Suspense fallback={<>...</>}>
								<Emails />
							</React.Suspense>
						}
					/>
					<Route
						path="analytics"
						element={
							<React.Suspense fallback={<>...</>}>
								<Analytics />
							</React.Suspense>
						}
					/>
					<Route
						path="registrations"
						element={
							<React.Suspense fallback={<>...</>}>
								<Registrations />
							</React.Suspense>
						}
					/>
					<Route
						path="main-presentation"
						element={
							<React.Suspense fallback={<>...</>}>
								<Presentation />
							</React.Suspense>
						}
					/>
					<Route
						path="advanced"
						element={
							<React.Suspense fallback={<>...</>}>
								<AdvancedPage />
							</React.Suspense>
						}
					/>
					<Route
						path="recordings"
						element={
							<React.Suspense fallback={<>...</>}>
								<Recordings />
							</React.Suspense>
						}
					/>
					<Route
						path="automation"
						element={
							<React.Suspense fallback={<>...</>}>
								<Automation />
							</React.Suspense>
						}
					/>
					<Route
						path="playlist"
						element={
							<React.Suspense fallback={<>...</>}>
								<Playlist />
							</React.Suspense>
						}
					/>
				</Route>
			</Route>
			<Route path="*" element={<Navigate to="/webinars-manager/events" />} />
		</Routes>
	);
};

const App = () => {
	return (
		<AppProviders>
			<Toast />
			<AppRoutes />
		</AppProviders>
	);
};

export default App;
