import { createContext, useContext, useEffect, useState } from "react";
import { UserRole } from "../models/User";
import { captureException } from "@sentry/browser";
import { ApiClient } from "../lib/api/client";

const AuthErrorCodes = {
	userInactive: "userInactive",
	webinarsNotAllowed: "webinarsNotAllowed",
};

export interface AuthUser {
	_id: string;
	organizationId?: string;
	email: string;
	roles: UserRole[];
	orToken?: string;
	exp: number;
	iat: number;
}

interface AuthContextProps {
	token?: string;
	orToken?: string;
	user?: AuthUser;
	isWebinarsEnabled?: boolean;
	hasInitialLoadingDone?: boolean;
	isUserActive?: boolean;
	logout: () => void;
}

function parseJwt<T>(token: string) {
	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join("")
	);

	return JSON.parse(jsonPayload) as T;
}

const TokenCookieKey = "x-auth-token";
const ORTokenCookieKey = "openreel-user";
const getCookies = () =>
	document.cookie
		.split(";")
		.map((cookie) => cookie.split("=").map((c) => c.trim()));

type CookiesList = ReturnType<typeof getCookies>;

const setCookie = (
	key: string,
	value: string,
	domain: string,
	expires: string
) => {
	document.cookie = `${key}=${value}; Path=/; Domain=${domain}; Expires=${expires}`;
};

const removeCookie = (key: string, domain: string) => {
	document.cookie = `${key}=; Path=/; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export function setAccessTokenCookie(token: string) {
	const decoded = parseJwt<AuthUser>(token);
	const expires = new Date(decoded.exp * 1000);
	setCookie(
		TokenCookieKey,
		token,
		import.meta.env.VITE_DOMAIN,
		expires.toUTCString()
	);
}

function getAppAuthToken(cookies: CookiesList) {
	let currentAppToken: string;
	let currentDecodedAppToken: AuthUser;
	for (const [key, token] of cookies) {
		if (key === TokenCookieKey) {
			const decoded = parseJwt<AuthUser>(token);
			const shouldUserToken =
				!currentAppToken || currentDecodedAppToken.iat < decoded.iat;
			if (shouldUserToken) {
				currentAppToken = token;
				currentDecodedAppToken = parseJwt<AuthUser>(token);
			}
		}
	}
	return {
		token: currentAppToken,
		decoded: currentDecodedAppToken,
	};
}

function getORCookieData(cookies: CookiesList) {
	const orCookie = cookies.find((c) => c[0] === ORTokenCookieKey);
	if (orCookie && orCookie[1]) {
		try {
			const orData = JSON.parse(decodeURIComponent(orCookie[1]));
			const orToken = orData["auth_token"];
			const isWebinarsEnabled = orData.data?.webinarAllowed;
			return { orToken, isWebinarsEnabled };
		} catch (e) {
			captureException(`Failed decoding openreel user cookie: ${e}`);
		}
	}
	return {};
}

interface APIAuthUser {
	_id: string;
	token: string;
	dateCreated: string;
	email: string;
	firstName: string;
	isSubscribed: boolean;
	isVerified: boolean;
	languages: string[];
	lastName: string;
	profileImageUrl: string;
	referralCode: string;
	roles: string[];
}

async function getNewAppToken(orToken: string) {
	const apiClient = new ApiClient();
	const {
		data: { user },
	} = await apiClient.post<{ data: { user: APIAuthUser } }>(
		"/auth/or",
		{
			// This must have a value as passport uses this field as username and password, as it's considered local strategy
			openReelToken: orToken ? orToken : "empty",
		},
		"include"
	);
	return { token: user.token, decoded: parseJwt<AuthUser>(user.token) };
}

async function getAndSetNewAppToken(
	orToken: string,
	isWebinarsEnabled: boolean
) {
	const newTokenData = await getNewAppToken(orToken);
	setAccessTokenCookie(newTokenData.token);
	return { ...newTokenData, orToken, isWebinarsEnabled };
}

async function validateOrToken(currentOrToken: string) {
	const apiClient = new ApiClient();
	const {
		data: { token },
	} = await apiClient.get<{ data: { token: string } }>("/auth/or-token");
	return currentOrToken === token;
}

async function loadTokens(cookies: CookiesList): Promise<{
	token?: string;
	orToken?: string;
	decoded?: AuthUser;
	isWebinarsEnabled?: boolean;
}> {
	const { token, decoded } = getAppAuthToken(cookies);
	// Not needed anymore as cookie is HTTPOnly

	const httpOnlyCookie = true;
	if (!httpOnlyCookie) {
		const { orToken, isWebinarsEnabled } = getORCookieData(cookies);
		if (!decoded) {
			if (!orToken) {
				return;
			}
			return getAndSetNewAppToken(orToken, true);
		}
		if (!orToken) {
			if (decoded.orToken) {
				return;
			}
			return { token, decoded, isWebinarsEnabled: true };
		}
		if (decoded.orToken !== orToken) {
			return getAndSetNewAppToken(orToken, isWebinarsEnabled);
		}
		return { token, decoded, orToken, isWebinarsEnabled };
	} else {
		const isWebinarsEnabled = true;
		const orToken = "testing-string";
		if (!decoded) {
			return getAndSetNewAppToken("", true);
		}
		const isOrTokenSame = await validateOrToken(decoded.orToken);
		if (!isOrTokenSame) {
			return getAndSetNewAppToken("", true);
		}
		return { token, decoded, orToken, isWebinarsEnabled };
	}
}

const AuthContext = createContext<AuthContextProps>({
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	logout: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
	const [token, setToken] = useState<string>();
	const [orToken, setOrToken] = useState<string>();
	const [isWebinarsEnabled, setIsWebinarsEnabled] = useState<boolean>();
	const [isUserActive, setIsUserActive] = useState<boolean>();
	const [user, setUser] = useState<AuthUser>();
	const [hasInitialLoadingDone, setHasInitialLoadingDone] = useState<boolean>();
	useEffect(() => {
		loadTokens(getCookies())
			.then((result) => {
				if (result) {
					setToken(result.token);
					setUser(result.decoded);
					setOrToken(result.orToken);
					setIsUserActive(true);
					setIsWebinarsEnabled(result.isWebinarsEnabled);
				}
				setHasInitialLoadingDone(true);
			})
			.catch((e) => {
				setHasInitialLoadingDone(true);
				captureException(`Error loading auth token: ${e}`);
			});
	}, []);
	useEffect(() => {
		async function onUnauthorizedResponse(res: Response) {
			const data = await res.json();
			if (data.code === AuthErrorCodes.userInactive) {
				setIsUserActive(false);
				return;
			}
			if (data.code === AuthErrorCodes.webinarsNotAllowed) {
				setIsWebinarsEnabled(false);
				return;
			}
			setToken(undefined);
			setUser(undefined);
			setOrToken(undefined);
		}
		ApiClient.addUnauthorizedListener(onUnauthorizedResponse);
		return () => {
			ApiClient.removeUnauthorizedListener(onUnauthorizedResponse);
		};
	}, []);
	useEffect(() => {
		if (token) {
			setUser(parseJwt<AuthUser>(token));
		} else {
			setUser(null);
		}
	}, [token]);
	const logout = async () => {
		setToken(undefined);
		setUser(undefined);
		setOrToken(undefined);
		removeCookie(TokenCookieKey, "." + import.meta.env.VITE_DOMAIN);
		removeCookie(ORTokenCookieKey, "." + import.meta.env.VITE_OR_DOMAIN);
		const apiClient = new ApiClient();
		await apiClient.get("/auth/logout");
	};
	return (
		<AuthContext.Provider
			value={{
				token,
				orToken,
				user,
				isWebinarsEnabled,
				isUserActive,
				hasInitialLoadingDone,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
